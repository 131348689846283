import React from 'react'
import {
  Box,
  Text,
  Flex,
  Icon,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverBody,
  Link,
} from '@chakra-ui/react'
import { BiLinkExternal } from 'react-icons/bi'
import { MarkdownText } from 'components/common/Text'
import { isArrayLength } from 'utils/helpers'

export const CollapsiblePanel = ({ action }) => {
  return !!action.description && (
    <Box w="100%">
      <Text fontWeight="600" fontSize="sm" mt="8px">
        {action.date}
      </Text>
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <MarkdownText fontWeight="400">
          {action.description}
        </MarkdownText>

        {isArrayLength(action.links) && (
          <Popover trigger="hover" isLazy>
            <PopoverTrigger>
              <Button
                position="relative"
                top="0px"
                bg="transparent"
                _hover={{ background: 'transparent' }}
              >
                <Icon className="share__icon" as={BiLinkExternal} />
              </Button>
            </PopoverTrigger>
            <PopoverContent p="10px">
              <PopoverBody>
                {action.links.map((link, i) => (
                  <Box key={`${action.id}_${i}`}>
                    <Link
                      noOfLines={1}
                      display="-webkit-box"
                      href={link}
                      target="_blank"
                      title="View News Source"
                      color="blue"
                      fontSize="xs"
                    >
                      {link}
                    </Link>
                  </Box>
                  ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </Box>
  )
}
