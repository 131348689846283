import { Box, Divider, Heading, HStack, Image, Text, Flex, Container, Icon} from "@chakra-ui/react"
import { Link } from "gatsby"
import Seo from "components/common/SEO"
import { GradeBadgeInternal, StatusBadge } from "components/common/Status"
import { MarkdownText } from "components/common/Text"
import { Shell } from "components/layout/Shell"
import { hasFlag } from "country-flag-icons"
import { graphql } from "gatsby"
import React, { useMemo, useEffect } from "react"
import SignUpStyles from "../common/SignUpStyles"
import { getCountryData } from "utils/data"
import { MdChevronLeft } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import { CollapsableUpdates } from "./CollapsableUpdates"
import { isArrayLength } from "utils/helpers"

const Country = ({ data }) => {
  const country = useMemo(() => getCountryData(data), [data])
  console.log(country)
  const flagSupported = useMemo(() => {
    return hasFlag(country.countryCode)
  }, [country])

  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.everyaction.com/ea-actiontag/at.js";
    document.body.appendChild(script);
  }, [])

  return (
    <Shell>
      <Seo title={country.name} />
      <Container maxW="6xl">
        <Flex mt="40px">
            <Link to="/" replace >
              <Flex alignItems="center">
                <MdChevronLeft />
                <Text  fontWeight="600" color="#000000" fontSize="xs" textTransform="uppercase" letterSpacing="1px">
                  <Text as="span" textDecor="underline">Sanctions Tracker</Text> - Country
                </Text>
              </Flex>
            </Link>
        </Flex>
        <Flex justifyContent="space-between" p={{base: "0 10px", lg: "0 30px"}} m="70px 0 40px" direction={{base:"column", lg: "unset"}}>
          <Flex direction="column" justifyContent="start" alignItems="flex-start" w={{base:"100%", lg: "35%"}}>
            <Flex direction="column" w="100%">
              <Flex alignItems="center">
                {flagSupported && (
                  <Image
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.countryCode}.svg`} 
                    w={{base: "40px", lg: "40px"}}
                    h={{base: "40px", lg: "40px"}}
                    objectFit="cover"
                    borderRadius="100%"
                    border="solid 1px lightgray"
                  />
                )}
                <Heading ml="15px"  fontWeight="600" color="#363565" fontSize={{base: "20px", lg: "25px"}}>{country.name}</Heading>
              </Flex>
              <Flex justifyContent="space-between" w="100%" m="40px 0 0 0" borderBottom="solid 2px #363565" >
                <Text pb="5px" fontSize="sm"  fontWeight="800" textTransform="uppercase" letterSpacing="2px">Overall Score</Text>
                  <Flex alignItems="center">
                    <Text fontSize="sm"   fontWeight="800" textTransform="uppercase" letterSpacing="2px">
                      Details
                    </Text>
                    <Icon as={BsArrowRight} ml="10px" fontSize="25px" />
                  </Flex>
              </Flex>
              <Flex w="100%" m="30px 0 20px 0">
                {country.grade.grade && (
                  <GradeBadgeInternal
                    grade={country.grade.grade}
                    status={country.grade.status}
                  />
                )}
              </Flex>
            </Flex>

            {country.grade.description && (
              <MarkdownText
                mt={1}
                textAlign="left"
                 fontWeight="400" fontSize="md" 
              >
                {country.grade.description}
              </MarkdownText>
            )}
          </Flex>
          <Flex w={{base:"100%", lg: "60%"}} pl={{base:"0", lg: "2%"}} mt={{base: "20px", lg: "0"}}>
            <Box> 
              {country.proposals.map(proposal => (
                <Box key={proposal.id}>
                  <HStack spacing="3" justify="space-between" alignItems="center">
                    <Heading size="md" fontWeight="800" >{proposal.title}</Heading>
                    <StatusBadge status={proposal.status.status} />
                  </HStack>
                  {proposal.description && (
                    <Box borderLeft="3px solid #eee" pl="3" py=".5">
                      <MarkdownText mt="5px" maxW={{base:"100%", lg: "80%"}} fontWeight="400" fontSize="xs">
                        {proposal.description}
                      </MarkdownText>
                    </Box>
                  )}
                  {/* <Divider /> */}
                  <MarkdownText  mt="10px" maxW={{base:"100%", lg: "80%"}} fontWeight="400" fontSize="sm">
                    {proposal.status.description}
                  </MarkdownText>

                  {isArrayLength(proposal.actions) && (
                    <CollapsableUpdates actions={proposal.actions} />
                  )}
                  <Divider m="20px 0" /> 
                </Box>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Container>

      <Container w="100%" maxW="100%" mt="0px" p="0" >
        <Flex mt="0px" w="100%" id="form__wrapper" justifyContent="center" alignItems="center" p={{base: "0px 0 0 0"}}>
          <div
            className="ngp-form"
            data-form-url="https://secure.everyaction.com/v1/Forms/niNSMwtg1UaNM5znlU57Hw2"
            data-fastaction-endpoint="https://fastaction.ngpvan.com"
            data-inline-errors="true"
            data-fastaction-nologin="true"
            data-databag-endpoint="https://profile.ngpvan.com"
            data-databag="everybody"
            data-mobile-autofocus="false"
          />
        </Flex>
        <SignUpStyles />
      </Container>
      
    </Shell>
  )
}

export const query = graphql`
query CountryQuery($id: String!) {
  country: contentfulCountry(id: {eq: $id}) {
		id
    name
    countryCode
    proposal_status {
      proposal {
        id
      }
      status {
        title
      }
      description {
        internal {
          content
        }
      }
    }
    action {
      id
      proposal {
        id
        title
        shortTitle
      }
      countries {
        id
        name
        countryCode
      }
      status {
        title
      }
      description {
        internal {
          content
        }
      }
      actionDate
      createdAt
      links
      reportCard
    }
    overall_grade {
      grade
      status {
        title
      }
      description {
        internal {
          content
        }
      }
    }
  }
  proposalList: contentfulList(id: {eq: "9c731be6-0dce-5011-8964-7e880f084406"}) {
    entries {
      ... on ContentfulProposal {
				id
        title
        shortTitle
        description {
          internal {
            content
          }
        }
      }
    }
  }
}
`

export default Country