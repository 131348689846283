import React from 'react'
import {
  Box,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Divider,
} from '@chakra-ui/react'
import { CollapsiblePanel } from './CollapsiblePanel'

// TODO: Make collapse state controlled, and make button change to "Hide Updates" on show
export const CollapsableUpdates = ({ actions }) => {
  return (
    <Flex>
      <Accordion defaultIndex={[1]} allowMultiple>
        <AccordionItem border="none" outline="none" m="20px 0 0">
          <AccordionButton
            p="0"
            _hover={{ background: 'none' }}
            w="auto"
          >
            <Box flex="1" textAlign="left" border="none">
              <Text color="#282828" position="relative" fontSize="sm">
                <Text
                  as="span"
                  top="-4px"
                  position="relative"
                  left="0"
                  bottom="0"
                  fontWeight="500"
                >
                  Show Updates
                </Text>
                <Text
                  as="span"
                  h="3px"
                  w="100%"
                  bg="#000000"
                  color="transparent"
                  position="absolute"
                  left="0"
                  bottom="0"
                >
                  .
                </Text>
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel p="0">
            <VStack
              mt="2"
              spacing="5px"
              divider={<Divider />}
            >
              {actions.map(action => (
                <CollapsiblePanel
                  action={action}
                  key={action.id}
                />
              ))}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}
